/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

$md: 768px;

.split-pane-visible .split-pane-side {
  max-width: 7rem !important;
  min-width: 7rem !important;
}

ion-menu {
  width: 7rem !important;
}

ion-modal.add-by-category {
  --width: 1000px;
}

.dark-spinner-loader {
  --spinner-color: var(--ion-color-dark);
}

// // Set the zoom to 0.8 when the screen size is less than 1200px
// @media (max-width: 1300px) {
//   :root {
//     zoom: 0.8;
//   }
// }

// // Set the zoom to 1 when the screen size is greater than or equal to 1200px
// @media (min-width: 1300px) {
//   :root {
//     zoom: 1;
//   }
// }

ion-input {
  caret-color: #000000 !important;
}

ion-textarea .native-textarea.sc-ion-textarea-md {
  caret-color: #000000 !important;
}

ion-textarea {
  --highlight-color-focused: transparent !important;  /* Hides the underline when focused */
  --highlight-color-invalid: transparent !important;  /* Hides the underline if the input is invalid */
  --highlight-color-valid: transparent !important;    /* Hides the underline if the input is valid */
}

ion-input input {
  caret-color: #000000 !important;
}

